import { CircularProgress, Button as MuiButton } from '@mui/material';
import style from './styles';

export interface Props {
  title?: string;
  color: 'primary' | 'secondary';
  variant?: 'text' | 'contained' | 'outlined';
  size?: 'small' | 'big';
  type?: 'title' | 'submit';
  icon?: JSX.Element;
  handleClick?(): void;
  disabled?: boolean;
  children?: JSX.Element;
  fetching?: boolean;
}

const Button = ({
  title,
  children,
  color,
  icon,
  handleClick,
  variant = 'contained',
  type,
  disabled,
  size,
  fetching,
}: Props) => {
  return (
    <>
      <MuiButton
        sx={style}
        className={`${[`color-${color}`]} ${[`variant-${variant}`]}  ${[`type-${type}`]} ${[`size-${size}`]}`}
        color={color}
        type={(type === 'submit' && 'submit') || 'button'}
        variant={variant}
        onClick={handleClick}
        disableRipple={type === 'title'}
        disabled={disabled}
      >
        {icon}
        <p> {title}</p>
        {children}
        {fetching && <CircularProgress size={16} color="inherit" className="progress-icon" />}
      </MuiButton>
    </>
  );
};
export default Button;
