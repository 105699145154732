/* tslint:disable */
/* eslint-disable */
/**
 * API ImmoPlus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccidentType } from '../models';
// @ts-ignore
import { City } from '../models';
/**
 * RepositoryApi - axios parameter creator
 * @export
 */
export const RepositoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve accidents types
         * @summary GET /repository/accidents-types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccidentsTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/repository/accidents-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get array of cities by postcode or city
         * @summary GET /repository/cities
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByPostCodeOrCity: async (value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'value' is not null or undefined
            assertParamExists('getCitiesByPostCodeOrCity', 'value', value)
            const localVarPath = `/repository/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepositoryApi - functional programming interface
 * @export
 */
export const RepositoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepositoryApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve accidents types
         * @summary GET /repository/accidents-types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccidentsTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccidentType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccidentsTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get array of cities by postcode or city
         * @summary GET /repository/cities
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitiesByPostCodeOrCity(value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<City>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitiesByPostCodeOrCity(value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RepositoryApi - factory interface
 * @export
 */
export const RepositoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepositoryApiFp(configuration)
    return {
        /**
         * Retrieve accidents types
         * @summary GET /repository/accidents-types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccidentsTypes(options?: any): AxiosPromise<Array<AccidentType>> {
            return localVarFp.getAccidentsTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * get array of cities by postcode or city
         * @summary GET /repository/cities
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesByPostCodeOrCity(value: string, options?: any): AxiosPromise<Array<City>> {
            return localVarFp.getCitiesByPostCodeOrCity(value, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCitiesByPostCodeOrCity operation in RepositoryApi.
 * @export
 * @interface RepositoryApiGetCitiesByPostCodeOrCityRequest
 */
export interface RepositoryApiGetCitiesByPostCodeOrCityRequest {
    /**
     * 
     * @type {string}
     * @memberof RepositoryApiGetCitiesByPostCodeOrCity
     */
    readonly value: string
}

/**
 * RepositoryApi - object-oriented interface
 * @export
 * @class RepositoryApi
 * @extends {BaseAPI}
 */
export class RepositoryApi extends BaseAPI {
    /**
     * Retrieve accidents types
     * @summary GET /repository/accidents-types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepositoryApi
     */
    public getAccidentsTypes(options?: AxiosRequestConfig) {
        return RepositoryApiFp(this.configuration).getAccidentsTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get array of cities by postcode or city
     * @summary GET /repository/cities
     * @param {RepositoryApiGetCitiesByPostCodeOrCityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepositoryApi
     */
    public getCitiesByPostCodeOrCity(requestParameters: RepositoryApiGetCitiesByPostCodeOrCityRequest, options?: AxiosRequestConfig) {
        return RepositoryApiFp(this.configuration).getCitiesByPostCodeOrCity(requestParameters.value, options).then((request) => request(this.axios, this.basePath));
    }
}
