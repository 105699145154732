/* tslint:disable */
/* eslint-disable */
/**
 * Api Contact
 * Points d\'entrées REST pour la gestion des contacts dans les applications Bessé 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export * from './apiContact/oprations-api';

