import { ErrorApi } from '@/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { Accident, AccidentApiFactory, AccidentRef } from './swagger';

export interface IApi {
  postAccident(body: Accident): Promise<number>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const accidentsApi = AccidentApiFactory({ isJsonMime: () => true }, endpoint, axiosClient);

  const postAccident = (body: Accident): Promise<number> =>
    new Promise((resolve, reject) => {
      accidentsApi
        .postAccidents(body)
        .then((response: AxiosResponse<AccidentRef>) => {
          resolve(response.status);
        })
        .catch((error: ErrorApi) => {
          reject(error);
        });
    });

  return { postAccident };
};

export default create;
