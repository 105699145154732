import Keycloak, { KeycloakConfig, KeycloakError } from 'keycloak-js';
import { getConfig } from '../config';

// export const Keycloak = typeof window !== 'undefined' ? require('keycloak-js') : null;

let keycloak: Keycloak;
/**
 * Initialise l'instance Keycloak
 * @param config
 */

export function keycloakConfig() {
  const realm = getConfig('NEXT_PUBLIC_IMMO_KEYCLOAK_REALM');
  const clientId = getConfig('NEXT_PUBLIC_IMMO_KEYCLOAK_CLIENT_ID');
  const url = getConfig('NEXT_PUBLIC_IMMO_KEYCLOAK_URL');
  return { realm, clientId, url } as KeycloakConfig;
}
export function keycloakAuth() {
  // const str: string = keycloak;

  // const keycloakConfiguration: KeycloakConfig = await keycloakConfig;
  // keycloak = Keycloak(keycloakConfiguration);

  const keycloakConfiguration2 = keycloakConfig();

  const keycloakConfiguration: KeycloakConfig = keycloakConfiguration2;

  keycloak = new Keycloak(keycloakConfiguration);

  return keycloak;
}
/**
 * Déconnexion de l'utilisateur
 */
export const logout = () => {
  const { protocol, port, hostname } = window.location;
  const redirectUri = protocol + '//' + hostname + (port !== '' ? ':' + port : '');
  sessionStorage.clear();
  localStorage.clear();
  keycloak.logout({
    redirectUri,
  });
};

export const getKeycloakParsed = () => {
  return keycloak && keycloak.tokenParsed && keycloak.tokenParsed;
};
/**
 * Retourne l'access token stocké par Keycloak
 */
export const getToken = (): string | undefined => keycloak.token;

/**
 * Retourne l'access token stocké par Keycloak
 */
export const getEmail = (): string | undefined => {
  return keycloak && keycloak.tokenParsed && keycloak.tokenParsed['email'];
};

/**
 * Retourne l'access token stocké par Keycloak
 */
export const getUserName = (): string | undefined => {
  return keycloak && keycloak.tokenParsed && keycloak.tokenParsed['preferred_username'];
};

/**
 * Détermine si un utilisateur est connecté
 */
export const isAuthenticated = (): boolean | undefined => keycloak && keycloak.authenticated;

/**
 * Récupération des roles
 */
export const getRoles = () => {
  return keycloak && keycloak.realmAccess && keycloak.realmAccess.roles;
};

export const isTokenExpired = (): boolean => {
  return keycloak.isTokenExpired(10);
};
export const updateToken = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    keycloak
      .updateToken(30)
      .then((refreshed: boolean | PromiseLike<boolean>) => {
        resolve(refreshed);
      })
      .catch((error: KeycloakError) => {
        console.log('an error has occurred');
        console.log(error);
        reject(error);
      });
  });

/**
 * Retourne l'access token stocké par Keycloak
 */
export const getName = (): string | undefined => {
  return keycloak && keycloak.tokenParsed && keycloak.tokenParsed['name'];
};
