import { ErrorApi } from '@/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { Property, SiteApiFactory } from './swagger';

export interface IApi {
  getProperties(params: { siteId: number; date: string }): Promise<Property[]>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const sitesApi = SiteApiFactory({ isJsonMime: () => true }, endpoint, axiosClient);

  const getProperties = (params: { siteId: number; date: string }): Promise<Property[]> =>
    new Promise((resolve, reject) => {
      sitesApi
        .getSitesProperties(params.siteId, params.date)
        .then((response: AxiosResponse<Property[]>) => {
          resolve(response.data);
        })
        .catch((error: ErrorApi) => {
          reject(error);
        });
    });

  return { getProperties };
};

export default create;
