import { ErrorApi } from '@/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { AccidentType, City, RepositoryApiFactory } from './swagger';

export interface IApi {
  getAccidentTypes(): Promise<AccidentType[]>;
  getRepositoryCities(value: string): Promise<City[]>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const repositoryApi = RepositoryApiFactory({ isJsonMime: () => true }, endpoint, axiosClient);

  const getAccidentTypes = (): Promise<AccidentType[]> =>
    new Promise((resolve, reject) => {
      repositoryApi
        .getAccidentsTypes()
        .then((response: AxiosResponse<AccidentType[]>) => {
          resolve(response.data);
        })
        .catch((error: ErrorApi) => {
          reject(error);
        });
    });

  const getRepositoryCities = (value: string): Promise<City[]> =>
    new Promise((resolve, reject) => {
      repositoryApi
        .getCitiesByPostCodeOrCity(value)
        .then((response: AxiosResponse<City[]>) => {
          resolve(response.data);
        })
        .catch((error: ErrorApi) => {
          reject(error);
        });
    });
  return { getAccidentTypes, getRepositoryCities };
};

export default create;
