import { ErrorApi } from '@/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { User, UserApiFactory } from './swagger';

export interface IApi {
  getUsers(email: string): Promise<User>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const usersApi = UserApiFactory({ isJsonMime: () => true }, endpoint, axiosClient);

  const getUsers = (email: string): Promise<User> =>
    new Promise((resolve, reject) => {
      usersApi
        .getUserByEmail(email)
        .then((response: AxiosResponse<User>) => {
          resolve(response.data);
        })
        .catch((error: ErrorApi) => {
          reject(error);
        });
    });

  return { getUsers };
};

export default create;
