import { Alert, Box, CircularProgress } from '@mui/material';
import { Component, ErrorInfo } from 'react';
import style from './styles';
import Button from '@/components/Button';
import { ErrorApi } from '@/types';

interface Props {
  children: JSX.Element;
  error?: ErrorApi | null;
  loading?: boolean;
  onRetry?(): void;
  onDisconnect?(): void;
  skeleton?: JSX.Element;
}

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}
/**
 * Composant pour gérer les chargements en cours :
 * - Affichage d'un loader si chargement en cours
 * - Affichage d'un message et d'un bouton "Réessayer" en cas d'erreur
 * - Affichage des enfants sinon
 */
class LoadingErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  handleRetry = () => {
    if (this.props.onRetry) {
      this.props.onRetry();
      this.setState({
        error: undefined,
        errorInfo: undefined,
      });
    } else {
      window.location.reload();
    }
  };
  render() {
    const { children, error, loading, onDisconnect, skeleton } = this.props;

    if (loading) {
      return skeleton ? skeleton : <CircularProgress />;
    } else if (error || this.state.error) {
      return (
        <Box component="div" sx={style}>
          {error && error.response ? (
            <Alert severity="error" className="errorBoundary-msg">
              {error.response.data.message}
            </Alert>
          ) : (
            <>
              <Alert severity="error" className="errorBoundary-msg">
                Une erreur est survenue
              </Alert>
              <details style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </details>
            </>
          )}
          <Button color="primary" title={'Réessayer'} handleClick={this.handleRetry} />
          {onDisconnect && (
            <Button color="primary" variant="text" title={'Se déconnecter'} handleClick={onDisconnect} />
          )}
        </Box>
      );
    }
    return children;
  }
}

export default LoadingErrorBoundary;
