'use client';

import Api, { IApi } from '@/services/api';
import contactApi, { IApi as IContactApi } from '@/services/apiContact';
import newsApi, { IApi as INewsApi } from '@/services/apiNews';

import { ItemRepository, User } from '@/services/api/swagger';
import { getApiContactEndpoint, getApiEndpoint, getApiNewsEndpoint } from '@/services/config';
import { getEmail, isTokenExpired, logout, updateToken } from '@/services/config/keycloak';
import { ErrorApi } from '@/types';
import { usePathname } from 'next/navigation';
import { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import LoadingErrorBoundary from '../ErrorBoundary';

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
interface State {
  apiContact?: IContactApi;
  apiNews?: INewsApi;
  api?: IApi;
  user?: User;
  site?: ItemRepository;
  setSite: (param?: ItemRepository) => void;
}
const Context = createContext<State>({ api: undefined, setSite: () => {} });

// A "provider" is used to encapsulate only the
// components that needs the state in this context

interface Props {
  children: JSX.Element;
}
function ContextProvider({ children }: Props) {
  const apiContact = getApiContactEndpoint();
  const apiContactCreate: IContactApi | undefined = apiContact ? contactApi.create(apiContact) : undefined;

  const apiNews = getApiNewsEndpoint();
  const apiNewsCreate: INewsApi | undefined = apiNews ? newsApi.create(apiNews) : undefined;

  const api = getApiEndpoint();
  const apiCreate: IApi | undefined = api ? Api.create(api) : undefined;

  const pathname = usePathname();
  const email = getEmail();
  const [site, setSite] = useState<ItemRepository | undefined>(undefined);
  const { isFetching, error, data, refetch } = useQuery<User | undefined, ErrorApi | undefined>(
    ['user', email],
    () => (email && apiCreate?.getUsers(email)) || undefined,
    {
      retry: false,
      enabled: pathname !== '/redirect',
      refetchOnWindowFocus: false,
      // onSuccess: (data) => {
      //   setUser(data);
      // },
    },
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const tokenExpired = isTokenExpired();
      if (tokenExpired) {
        updateToken();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <LoadingErrorBoundary error={error} loading={isFetching} onRetry={refetch} onDisconnect={logout}>
      <Context.Provider
        value={{
          apiContact: apiContactCreate,
          apiNews: apiNewsCreate,
          api: apiCreate,
          user: data,
          site,
          setSite,
        }}
      >
        {children}
      </Context.Provider>
    </LoadingErrorBoundary>
  );
}

export { Context, ContextProvider };
