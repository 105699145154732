import { SxProps, Theme } from '@mui/material';
import { disgustingBrown, theme } from '../providers/ThemeRegistry/theme';

const style: SxProps<Theme> = [
  {
    boxShadow: 'none',
    borderRadius: 0,
    '& svg': {
      mr: '8px',
      fontSize: 12,
    },
    '&.color-primary': {
      padding: '12px 24px',
      boxShadow: 'none',
    },
    '&.color-secondary': {
      fontFamily: '"Playfair Display", serif',
      padding: '12px 24px',
      fontSize: '16px',
      letterSpacing: '0.5px',
      boxShadow: 'none',
      background: theme.palette.primary.light,
      borderRadius: '2px',
      border: '1px solid ' + theme.palette.primary.light,
      fontWeight: 300,
      color: 'white',
      textTransform: 'initial',
      '&:hover, &:focus, &:active': {
        background: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
    },
    '&.variant-outlined': {
      background: 'none',
      color: 'white',
      borderColor: 'white',
      '&:hover, &:focus, &:active': {
        background: 'white',
        color: theme.palette.primary.main,
      },
    },
    '&.variant-text': {
      background: 'none',
      padding: '12px 0',
      color: disgustingBrown,
      fontWeight: 'bold',
      textDecoration: 'underline',
      textTransform: 'initial',
      '& > svg': {
        height: '18px',
        color: disgustingBrown,
      },
      '&:hover, &:focus, &:active': {
        borderBottomColor: 'transparent',
      },
    },
    '& .progress-icon': {
      padding: 0,
      ml: 1,
      '& svg': {
        margin: 0,
      },
    },
    [theme.breakpoints.down('lg')]: {},
  },
];

export default style;
