import { ErrorApi } from '@/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { NousContacterInput, OprationsApiFactory } from './swagger';

export interface IApi {
  postContact(body: NousContacterInput): Promise<unknown>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const contactApi = OprationsApiFactory({ isJsonMime: () => true }, endpoint, axiosClient);

  const postContact = (body: NousContacterInput) =>
    new Promise((resolve, reject) => {
      contactApi
        .nousContacter(body)
        .then((response: AxiosResponse<void>) => {
          resolve(response.data);
        })
        .catch((error: ErrorApi) => {
          reject(error);
        });
    });

  return { postContact };
};

export default create;
