import { SxProps, Theme } from '@mui/material';

const style: SxProps<Theme> = [
  {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 'clamp(45ch, 90%, 1200px)',
    margin: 'auto',
    '& .errorBoundary-msg': {
      width: 'fit-content',
    },
    '& button:first-of-type': {
      marginTop: 2,
    },
  },
];

export default style;
