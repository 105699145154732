import { Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
export const lightBlue = '#60C5DB';
export const darkBlue = '#012B65';
export const greyBlue = '#EDE5DE';
export const lightRed = '#EB5757';
export const warning = '#FFB020';
export const error = '#FF000D';
export const success = '#83C438';
export const globalPaddingSize = '5%';
export const maxWindowWidth = '1620px';
export const primaryColor = '#014886';
export const notifications = '#EC6503';
export const disgustingBrown = '#9A8C7E';
export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: lightBlue,
    },
    secondary: {
      main: '#ff3f33',
    },
    grey: {
      900: '#191919',
      800: '#7A7A7B',
      700: '#828282',
      500: '#b0b0b3',
      300: '#E9E9EA',
      200: '#F5F7F8',
      100: '#f5f7f85c',
    },
    error: {
      main: error,
    },
    success: {
      main: success,
    },
    warning: {
      main: warning,
    },
  },
  transitions: {},
  zIndex: {},
  typography: {
    fontFamily: ['DINPro', '"Playfair Display", serif'].join(','),
    h1: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '80px',
      color: 'white',
      fontWeight: 'normal',
      lineHeight: 1,
      marginTop: '32px',
    },
    h2: {
      fontSize: '50px',
      fontFamily: '"Playfair Display"',
      color: lightBlue,
      marginBottom: '12px',
      paddingLeft: '76px',
      position: 'relative',
      fontWeight: 700,
    },

    h3: { fontSize: '18px', fontWeight: '500', textTransform: 'uppercase', color: 'white' },
    h4: {
      fontFamily: '"Playfair Display"',
      fontSize: '28px',
      fontWeight: '700',
      color: primaryColor,
    },
    h5: {
      fontFamily: '"DINPro", serif',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: '#7A7A7B',
      fontSize: 13,
    },
    h6: {
      color: darkBlue,
      fontSize: '34px',
      fontFamily: '"Playfair Display"',
      fontWeight: 850,
    },

    body1: {
      fontSize: 14,
    },
    body2: {
      // fontWeight: 'bold',
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          fontSize: 14,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1281, // taille écran gestionnaire
      xl: 1536,
    },
  },
});

// CONSTANTS NEEDING THEME TO BE INITIALIZED
export const greyBorder = '1px solid ' + theme.palette.grey[300];
export const scrollBar = {
  // FIREFOX
  scrollbarColor: theme.palette.primary.main + ' ' + theme.palette.grey[300],
  scrollbarWidth: 'thin',
  '&:hover, &:focus, &:active': {
    scrollbarColor: theme.palette.primary.dark + ' ' + theme.palette.grey[300],
  },
  // CHROME & EDGE
  '&::-webkit-scrollbar': {
    width: '7px',
    height: '7px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.grey[300],
    border: '3px solid white',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.primary.dark,
  },
};
