/* tslint:disable */
/* eslint-disable */
/**
 * API ImmoPlus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum DocumentEnum {
    DetailedDeclaration = 'DETAILED_DECLARATION',
    ReconditioningQuotation = 'RECONDITIONING_QUOTATION',
    MaterialInvoice = 'MATERIAL_INVOICE',
    Report = 'REPORT',
    RepairsCause = 'REPAIRS_CAUSE',
    OccupantsDamage = 'OCCUPANTS_DAMAGE',
    NaturalDisasterStatement = 'NATURAL_DISASTER_STATEMENT',
    OccupantsSearchLeak = 'OCCUPANTS_SEARCH_LEAK',
    FillingComplaint = 'FILLING_COMPLAINT',
    ReportAutomobile = 'REPORT_AUTOMOBILE',
    InvoiceAfterQuotation = 'INVOICE_AFTER_QUOTATION',
    RdfReport = 'RDF_REPORT',
    Other = 'OTHER'
}


