/* tslint:disable */
/* eslint-disable */
/**
 * API ImmoPlus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Accident } from '../models';
// @ts-ignore
import { AccidentRef } from '../models';
/**
 * AccidentApi - axios parameter creator
 * @export
 */
export const AccidentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creation of an accident
         * @summary POST /accidents
         * @param {Accident} accident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccidents: async (accident: Accident, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accident' is not null or undefined
            assertParamExists('postAccidents', 'accident', accident)
            const localVarPath = `/accidents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accident, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccidentApi - functional programming interface
 * @export
 */
export const AccidentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccidentApiAxiosParamCreator(configuration)
    return {
        /**
         * Creation of an accident
         * @summary POST /accidents
         * @param {Accident} accident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccidents(accident: Accident, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccidentRef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccidents(accident, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccidentApi - factory interface
 * @export
 */
export const AccidentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccidentApiFp(configuration)
    return {
        /**
         * Creation of an accident
         * @summary POST /accidents
         * @param {Accident} accident 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccidents(accident: Accident, options?: any): AxiosPromise<AccidentRef> {
            return localVarFp.postAccidents(accident, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for postAccidents operation in AccidentApi.
 * @export
 * @interface AccidentApiPostAccidentsRequest
 */
export interface AccidentApiPostAccidentsRequest {
    /**
     * 
     * @type {Accident}
     * @memberof AccidentApiPostAccidents
     */
    readonly accident: Accident
}

/**
 * AccidentApi - object-oriented interface
 * @export
 * @class AccidentApi
 * @extends {BaseAPI}
 */
export class AccidentApi extends BaseAPI {
    /**
     * Creation of an accident
     * @summary POST /accidents
     * @param {AccidentApiPostAccidentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccidentApi
     */
    public postAccidents(requestParameters: AccidentApiPostAccidentsRequest, options?: AxiosRequestConfig) {
        return AccidentApiFp(this.configuration).postAccidents(requestParameters.accident, options).then((request) => request(this.axios, this.basePath));
    }
}
