/* tslint:disable */
/* eslint-disable */
/**
 * API ImmoPlus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Property } from '../models';
/**
 * SiteApi - axios parameter creator
 * @export
 */
export const SiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve sites properties by site id
         * @summary GET /sites/{id}/properties
         * @param {number} id Site id
         * @param {string} date Accident date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesProperties: async (id: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSitesProperties', 'id', id)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getSitesProperties', 'date', date)
            const localVarPath = `/sites/{id}/properties`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteApi - functional programming interface
 * @export
 */
export const SiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve sites properties by site id
         * @summary GET /sites/{id}/properties
         * @param {number} id Site id
         * @param {string} date Accident date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSitesProperties(id: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Property>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSitesProperties(id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteApi - factory interface
 * @export
 */
export const SiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteApiFp(configuration)
    return {
        /**
         * Retrieve sites properties by site id
         * @summary GET /sites/{id}/properties
         * @param {number} id Site id
         * @param {string} date Accident date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSitesProperties(id: number, date: string, options?: any): AxiosPromise<Array<Property>> {
            return localVarFp.getSitesProperties(id, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSitesProperties operation in SiteApi.
 * @export
 * @interface SiteApiGetSitesPropertiesRequest
 */
export interface SiteApiGetSitesPropertiesRequest {
    /**
     * Site id
     * @type {number}
     * @memberof SiteApiGetSitesProperties
     */
    readonly id: number

    /**
     * Accident date
     * @type {string}
     * @memberof SiteApiGetSitesProperties
     */
    readonly date: string
}

/**
 * SiteApi - object-oriented interface
 * @export
 * @class SiteApi
 * @extends {BaseAPI}
 */
export class SiteApi extends BaseAPI {
    /**
     * Retrieve sites properties by site id
     * @summary GET /sites/{id}/properties
     * @param {SiteApiGetSitesPropertiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getSitesProperties(requestParameters: SiteApiGetSitesPropertiesRequest, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getSitesProperties(requestParameters.id, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}
