import Axios, { InternalAxiosRequestConfig } from 'axios';
import { getApiKey } from '../config';
import contactApi, { IApi as IContactApi } from './contact';

/**
 * Création du client Axios
 */
export const createAxiosClient = (endpoint: string) => {
  const axiosClient = Axios.create({
    baseURL: endpoint,
    // tslint:disable: quotemark
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    // tslint:enable: quotemark
  });
  /* Intercepteur Axios
   */
  axiosClient.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      /**
       * Permet d'ajouter dans le header de nos requêtes les identifiants de connexion.
       */
      // const tokenParsed = getKeycloakParsed();

      const apiKey = getApiKey();

      if (apiKey) {
        config.headers['X-Gravitee-Api-Key'] = getApiKey();
      }

      // Do something before request is sent
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  return axiosClient;
};

/**
 * Crée un nouveau client d'API
 * @param endpoint
 */
const create = (endpoint: string) => {
  const axiosClient = createAxiosClient(endpoint);
  return {
    ...contactApi(endpoint, axiosClient),
  };
};

// Fusion des interfaces des API en une interface unique

export type IApi = IContactApi;

export default { create };
