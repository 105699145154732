/* tslint:disable */
/* eslint-disable */
/**
 * API ImmoPlus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum RoleEnum {
    Abonnement = 'ABONNEMENT',
    AccesBeprems = 'ACCES_BEPREMS',
    AccesSinistre = 'ACCES_SINISTRE',
    AideEnLigne = 'AIDE_EN_LIGNE',
    ApiDepotPart = 'API_DEPOT_PART',
    ApiDoslocEcriture = 'API_DOSLOC_ECRITURE',
    ApiDoslocLecture = 'API_DOSLOC_LECTURE',
    ApiDoslocSuppression = 'API_DOSLOC_SUPPRESSION',
    ApiDosparEcriture = 'API_DOSPAR_ECRITURE',
    ApiDosparLecture = 'API_DOSPAR_LECTURE',
    ApiUtiEcriture = 'API_UTI_ECRITURE',
    ApiUtiLecture = 'API_UTI_LECTURE',
    Copropriete = 'COPROPRIETE',
    CreationAgrement = 'CREATION_AGREMENT',
    DetailCopropriete = 'DETAIL_COPROPRIETE',
    DetailSinistre = 'DETAIL_SINISTRE',
    DommageTravaux = 'DOMMAGE_TRAVAUX',
    FrontLecture = 'FRONT_LECTURE',
    GestionAdb = 'GESTION_ADB',
    GestionAide = 'GESTION_AIDE',
    GestionCsp = 'GESTION_CSP',
    GestionDoc = 'GESTION_DOC',
    GestionJustificatifs = 'GESTION_JUSTIFICATIFS',
    GestionRevenus = 'GESTION_REVENUS',
    HistoriqueSinistre = 'HISTORIQUE_SINISTRE',
    Mail = 'MAIL',
    ModifierBeprems = 'MODIFIER_BEPREMS',
    OuvertureServiceAdb = 'OUVERTURE_SERVICE_ADB',
    ParametrageEtat = 'PARAMETRAGE_ETAT',
    PrmdrtadbEcriture = 'PRMDRTADB_ECRITURE',
    PrmdrtadbLecture = 'PRMDRTADB_LECTURE',
    PrmutiadbEcriture = 'PRMUTIADB_ECRITURE',
    PrmutiadbLecture = 'PRMUTIADB_LECTURE',
    PrmutibcEcriture = 'PRMUTIBC_ECRITURE',
    PrmutibcLecture = 'PRMUTIBC_LECTURE',
    Sinistre = 'SINISTRE',
    SynthModificationStatut = 'SYNTH_MODIFICATION_STATUT',
    SynthValidation = 'SYNTH_VALIDATION',
    TdbdoslocCreation = 'TDBDOSLOC_CREATION',
    TdbdoslocModificationMiens = 'TDBDOSLOC_MODIFICATION_MIENS',
    TdbdoslocModificationTous = 'TDBDOSLOC_MODIFICATION_TOUS',
    TdbdoslocSuppression = 'TDBDOSLOC_SUPPRESSION',
    TdbdoslocValidation = 'TDBDOSLOC_VALIDATION',
    Utilisateur = 'UTILISATEUR',
    VisualisationAdb = 'VISUALISATION_ADB',
    VisualisationAgrement = 'VISUALISATION_AGREMENT'
}


