/* tslint:disable */
/* eslint-disable */
/**
 * Api Contact
 * Points d\'entrées REST pour la gestion des contacts dans les applications Bessé 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InvalidParameter } from '../models';
// @ts-ignore
import { NousContacterInput } from '../models';
/**
 * OprationsApi - axios parameter creator
 * @export
 */
export const OprationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Demande de contact d\'un utilisateur
         * @param {NousContacterInput} nousContacterInput Données du formulaire de contact
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nousContacter: async (nousContacterInput: NousContacterInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nousContacterInput' is not null or undefined
            assertParamExists('nousContacter', 'nousContacterInput', nousContacterInput)
            const localVarPath = `/nous-contacter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nousContacterInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OprationsApi - functional programming interface
 * @export
 */
export const OprationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OprationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Demande de contact d\'un utilisateur
         * @param {NousContacterInput} nousContacterInput Données du formulaire de contact
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nousContacter(nousContacterInput: NousContacterInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nousContacter(nousContacterInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OprationsApi - factory interface
 * @export
 */
export const OprationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OprationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Demande de contact d\'un utilisateur
         * @param {NousContacterInput} nousContacterInput Données du formulaire de contact
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nousContacter(nousContacterInput: NousContacterInput, options?: any): AxiosPromise<void> {
            return localVarFp.nousContacter(nousContacterInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for nousContacter operation in OprationsApi.
 * @export
 * @interface OprationsApiNousContacterRequest
 */
export interface OprationsApiNousContacterRequest {
    /**
     * Données du formulaire de contact
     * @type {NousContacterInput}
     * @memberof OprationsApiNousContacter
     */
    readonly nousContacterInput: NousContacterInput
}

/**
 * OprationsApi - object-oriented interface
 * @export
 * @class OprationsApi
 * @extends {BaseAPI}
 */
export class OprationsApi extends BaseAPI {
    /**
     * 
     * @summary Demande de contact d\'un utilisateur
     * @param {OprationsApiNousContacterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OprationsApi
     */
    public nousContacter(requestParameters: OprationsApiNousContacterRequest, options?: AxiosRequestConfig) {
        return OprationsApiFp(this.configuration).nousContacter(requestParameters.nousContacterInput, options).then((request) => request(this.axios, this.basePath));
    }
}
