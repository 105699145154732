let data: any;
export const loadConfig = (configuration: any) => {
  data = configuration;
};

export const getConfig = (key: string): string | undefined => {
  if (data) {
    return data[key];
  } else {
    return process.env[key];
  }
};

export const getInfoAppli = () => {
  return {
    objetMail: 'Contact depuis le site ImmoplusEnLigne',
    templateHeader: getConfig('NEXT_PUBLIC_IMMO_TEMPLATE_HEADER') || '',
    templateFooter: getConfig('NEXT_PUBLIC_IMMO_TEMPLATE_FOOTER') || '',
    templateMail: getConfig('NEXT_PUBLIC_IMMO_TEMPLATE_MAIL') || '',
    sender: getConfig('NEXT_PUBLIC_IMMO_SENDER') || '',
    senderName: getConfig('NEXT_PUBLIC_IMMO_SENDER_NAME') || '',
    destinataire: getConfig('NEXT_PUBLIC_IMMO_DESTINATAIRE') || '',
  };
};

export const getApiContactEndpoint = () => {
  return getConfig('NEXT_PUBLIC_IMMO_CONTACT_API') || null;
};

export const getApiNewsEndpoint = () => {
  return getConfig('NEXT_PUBLIC_API_NEWS_ENDPOINT') || null;
};
export const getApiEndpoint = () => {
  return getConfig('NEXT_PUBLIC_API_ENDPOINT') || null;
};

export const getKeycloakUrl = () => {
  return getConfig('NEXT_PUBLIC_IMMO_KEYCLOAK_URL') || null;
};

export const getKeycloakRealm = () => {
  return getConfig('NEXT_PUBLIC_IMMO_KEYCLOAK_REALM') || null;
};

export const getKeycloakClientId = () => {
  return getConfig('NEXT_PUBLIC_IMMO_KEYCLOAK_CLIENT_ID') || null;
};

export const getTagManager = () => {
  return getConfig('NEXT_PUBLIC_MATOMO_KEY') || null;
};

export const getIdAxeptio = () => {
  return getConfig('NEXT_PUBLIC_AXEPTIO_KEY') || null;
};

export const getApiKey = () => {
  return getConfig('NEXT_PUBLIC_TOKEN_API_CONTACT') || '';
};

export const getRedirectUrl = () => {
  return getConfig('NEXT_PUBLIC_REDIRECT_IMMOPLUS') || '';
};

export const getServletUrl = () => {
  return getConfig('NEXT_PUBLIC_SERVLET_URL') || '';
};
