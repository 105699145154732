import Axios, { InternalAxiosRequestConfig } from 'axios';
import { getToken } from '../config/keycloak';
import repositoryApi, { IApi as IRepositoryApi } from './repository';
import usersApi, { IApi as IUsersApi } from './users';
import accidentsApi, { IApi as IAccidentsApi } from './accidents';
import sitesApi, { IApi as ISitesApi } from './sites';

/**
 * Création du client Axios
 */
export const createAxiosClient = (endpoint: string) => {
  const axiosClient = Axios.create({
    baseURL: endpoint,
    // tslint:disable: quotemark
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    // tslint:enable: quotemark
  });
  /* Intercepteur Axios
   */
  axiosClient.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      /**
       * Permet d'ajouter dans le header de nos requêtes les identifiants de connexion.
       */
      // const tokenParsed = getKeycloakParsed();

      const token = getToken();
      if (token !== undefined || token !== null) {
        config.headers.Authorization = `Bearer ${token}` as string;
      }

      // Do something before request is sent
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  return axiosClient;
};

/**
 * Crée un nouveau client d'API
 * @param endpoint
 */
const create = (endpoint: string) => {
  const axiosClient = createAxiosClient(endpoint);
  return {
    ...repositoryApi(endpoint, axiosClient),
    ...usersApi(endpoint, axiosClient),
    ...sitesApi(endpoint, axiosClient),
    ...accidentsApi(endpoint, axiosClient),
  };
};

// Fusion des interfaces des API en une interface unique

export type IApi = IRepositoryApi & IUsersApi & IAccidentsApi & ISitesApi;

export default { create };
