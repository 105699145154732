import axios, { AxiosResponse } from 'axios';

export interface IApi {
  // TODO typage
  getActualites(langue: string): Promise<IApiResponse>;
}

export interface IType {
  id: string;
  label: string;
}

export interface IArticle {
  url: string;
  title: string;
  intro: string;
  img: string;
  type: IType;
  publication_date: number;
}

export interface IApiResponse {
  result: {
    [id: string]: IArticle;
  };
}

const create = (endpoint: string) => {
  const getActualites = (langue: string): Promise<IApiResponse> =>
    new Promise((resolve, reject) => {
      axios
        .get(endpoint + 'list', {
          params: {
            // TODO Ajouter ce token dans les configmap
            besse_token: 'lmw-test-token',
            count: 3,
            type: ['publication', 'actualite', 'evenement', 'expert'],
            sector: [45, 46, 5, 4, 44],
            language: langue,
          },
        })
        .then((response: AxiosResponse<IApiResponse>) => {
          resolve(response.data);
        })
        .catch((error: unknown) => reject(error));
    });
  return { getActualites };
};

export default create;
